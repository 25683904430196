import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { Modal } from '../components/Modal';
import { Navigation } from '../components/Navigation';
import { Spinner } from '../components/Spinner';
import { FileFieldComponent } from '../components/FileFieldComponent';
import { BiEdit } from 'react-icons/bi';
import { AdminHelpComponent } from "../components/AdminHelpComponent";

export const NewCoursePage = () => {

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [isDirectoryCreated, setIsDirectoryCreated] = useState(false);
  const [isStudentListUploaded, setIsStudentListUploaded] = useState(false);
  const [isStudentColoursUploaded, setIsStudentColoursUploaded] = useState(false);
  const [isActivated, setIsActivated] = useState(false);
  const [folderName, setFolderName] = useState("HCIS12023");
  const [showSpinner, setShowSpinner] = useState(false);
  const [namePrefix, setNamePrefix] = useState('HCI');
  const [courseSemester, setCourseSemester] = useState('S1');
  const [courseYear, setCourseYear] = useState('2023');
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedFile1, setSelectedFile1] = useState({});
  const [selectedFile1Name, setSelectedFile1Name] = useState("");
  const [selectedFile2, setSelectedFile2] = useState({});
  const [selectedFile2Name, setSelectedFile2Name] = useState("");
  const [whichFile, setWhichFile]= useState('file1');
  const [currentActiveCourse, setCurrentActiveCourse] = useState("");

  const [showStudentListUploadForm, setShowStudentListUploadForm] = useState(true);
  const [showStudentColourListUploadForm, setShowStudentColourListUploadForm] = useState(true);

  const navigationAction = (action) => {
    switch(action) {
      case "help": setShowModal(true); break;
      case "dashboard": window.location.href = "/admin-page"; break;
      case "create-new-course": window.location.href = "/admin-new-course"; break;
      case "download-csv": window.location.href = "/admin-download-page"; break;
      case "logout": window.location.href = "/auth/logout"; break;
    }
  }   

  const submitHandler1 = (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("uploadedFile", selectedFile1);
    formData.append("foldername", folderName);
    formData.append("filename", selectedFile1.name);
    formData.append("supportfiletype", "sfile1");

    setShowSpinner(true);
    axios.post(
      "/api/upload-support-files",
      formData,
      {
        headers: {
          "Content-Type" : "multipart/form-data",
          "Accept" : "application/json"
        }
      }
    ).then((response) => {
      setShowSpinner(false);
      setIsStudentListUploaded(true);
      setShowStudentListUploadForm(false);
    })
  }

  const submitHandler2 = (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("uploadedFile", selectedFile2);    
    formData.append("foldername", folderName);
    formData.append("filename", selectedFile2.name);
    formData.append("supportfiletype", "sfile2");

    setShowSpinner(true);
    axios.post(
      "/api/upload-support-files",
      formData,
      {
        headers: {
          "Content-Type" : "multipart/form-data",
          "Accept" : "application/json"
        }
      }
    ).then((response) => {
      setShowSpinner(false);
      setIsStudentColoursUploaded(true);
      setShowStudentColourListUploadForm(false);
    })
  }  

  const fileChangeHandler1 = (event) => {
    setSelectedFile1Name("student_list.csv");
    setSelectedFile1(event.target.files[0]);
  }

  const fileChangeHandler2 = (event) => {
    setSelectedFile2Name("student_colours.csv");
    setSelectedFile2(event.target.files[0]);
  }  

  const createSubdirectory = () => {
    const currentFolderName = namePrefix + courseSemester + courseYear;
    console.log(currentFolderName);    
    setFolderName((folderName) => currentFolderName);

    setShowSpinner(true);
    axios.post(
      "/api/create-course",
      {
        "foldername" : currentFolderName
      }
    ).then((response) => {
      setShowSpinner(false);
      if(response.data == "1") {
        setIsDirectoryCreated(true);
      } else {
        setAlertMessage("Error: The course already exists!");
        setShowModal2(true);
      }      
    })    
  }

  const activateCourse = () => {
    setShowSpinner(true);
    axios.post(
      "api/activate-course",
      {
        "foldername" : folderName
      }
    ).then((response) => {
      console.log(response)
      setShowSpinner(false);   
      window.location.href = "/admin-new-course"
    })   
  }

  const UploadStudentListContent = () => {
    if (showStudentListUploadForm) {
      return  <div className="bg-slate-200 p-5 rounded mb-5">
                  <form method="post" onSubmit={submitHandler1}>
                      <div className="py-2 font-semibold">
                          Upload student_list.csv :
                      </div>
                      <div>
                          <FileFieldComponent selectedFileName={selectedFile1Name}  onChangeHandler={(e) => fileChangeHandler1(e) } />
                      </div>
                      <button type="submit" className="mt-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded">Submit student_list.csv</button>
                  </form>
              </div>
    } else {
      return <div className="bg-slate-200 p-5 rounded mb-5 flex items-center">
                <div className="text-lg">student_list.csv uploaded</div> <div className="text-xl ml-5 cursor-pointer hover:text-sky-600" onClick={() => setShowStudentListUploadForm(true)}><BiEdit /></div>
              </div>
    }
  }

  const UploadStudentColourListContent = () => {
    if (showStudentColourListUploadForm) {
      return <div className="bg-slate-200 p-5 rounded mb-5">
              <form method="post" onSubmit={submitHandler2}>
                  <div className="py-2 font-semibold">
                      Upload student_colours.csv :
                  </div>
                  <div>
                      <FileFieldComponent selectedFileName={selectedFile2Name}  onChangeHandler={(e) => fileChangeHandler2(e) } />
                  </div>
                  <button type="submit" className="mt-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded">Submit student_colours.csv</button>
              </form>
          </div>
    } else {
      return <div className="bg-slate-200 p-5 rounded mb-5 flex items-center">
        <div className="text-lg">student_colours.csv uploaded</div> <div className="text-xl ml-5 cursor-pointer hover:text-sky-600" onClick={() => setShowStudentColourListUploadForm(true)}><BiEdit /></div>        
      </div>
    }
  }


  const SupportFilesMarkUp = () => {
    if(isDirectoryCreated) {
      return  <div className="mb-8">
                <div className="text-lg font-medium">Support Files</div>
                <div className="mt-5">
                
                    <UploadStudentListContent />

                    <UploadStudentColourListContent />

                </div>
              </div>
    }
    return null;
  }

  const ActiveCourseMarkup = () => {
    if(isDirectoryCreated && isStudentListUploaded && isStudentColoursUploaded) {
      return <div className="mb-8">
                <div className="text-lg font-medium">Activate Course</div>
                <div className="mt-5">
                    <div>
                        <button onClick={activateCourse} className="mt-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded">Start Cron Job</button>
                    </div>                    
                </div>
              </div>
    }
    return null;
  }

  const CourseNameMarkup = () => {
    if (!isDirectoryCreated) {
      return <div className="mb-8">
                <div className="text-lg font-medium">New Course Name</div>
                <div className="mt-5">
                  <div className="mb-3">
                    <label htmlFor="namePrefix">
                        <span className="pr-2">Name Prefix : </span>
                        <input
                            id="namePrefix"
                            type="text" 
                            name="namePrefix" 
                            className="border-solid rounded border-2 border-gray-200 py-1 px-2"
                            placeholder="HCI"
                            value={namePrefix}
                            onChange={(e) => setNamePrefix(e.target.value)}
                        />
                    </label>                  
                  </div>
                  <div className="mb-3">
                    <label htmlFor="namePrefix">
                        <span className="pr-2">Semester :</span>
                        <select 
                            name="semester" 
                            id="semester" 
                            className="border-solid rounded border-2 border-gray-200 py-1 px-2"
                            value={courseSemester}
                            onChange={(e) => setCourseSemester(e.target.value)}
                            >
                            <option value="S1">S1</option>
                            <option value="S2">S2</option>
                            <option value="SS">SS</option>                        
                        </select>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="year">
                    <span className="pr-2">Year :</span>
                        <input
                            type="text" 
                            name="year" 
                            className="border-solid rounded border-2 border-gray-200 py-1 px-2"
                            placeholder="2023"
                            value={courseYear}
                            onChange={(e) => setCourseYear(e.target.value)}
                        />
                    </label>
                  </div>   
                  <div>
                    <button className="mt-5 bg-transparent hover:bg-blue-500 
                                      text-blue-700 font-semibold hover:text-white 
                                      py-1 px-3 border border-blue-500 
                                      hover:border-transparent rounded"
                                      onClick={createSubdirectory}
                                      >
                                        Create Subdirectory
                    </button>  
                  </div>               
                </div>
             </div>
    }
    return  <div className="mb-8">
                <div className="text-lg font-medium">Course Name</div>
                <div className="mt-5">
                    The directory for the course {folderName} has already been created!
                </div>
            </div>   
  }


  useEffect(() => {
    setShowSpinner(true);
    axios.get(
      "/api/get-current-course"
    ).then((response) => {
        if(response.data.current_course) {
            const current_course_obj = response.data.current_course;
            const semesterYear = current_course_obj.name.slice(-6);

            setCourseYear(semesterYear.slice(-4));
            setCourseSemester(semesterYear.slice(0,2));
            setNamePrefix(current_course_obj.name.slice(0,-6));

            setFolderName(current_course_obj.name);
            setIsDirectoryCreated(current_course_obj.isDirectoryCreated); 
            setIsStudentListUploaded(current_course_obj.isStudentListUploaded);
            setIsStudentColoursUploaded(current_course_obj.isStudentColoursUploaded);

            console.log(current_course_obj);

            if (!current_course_obj.isStudentListUploaded) {
              setShowStudentListUploadForm(true);
            }

            if (!current_course_obj.isStudentColoursUploaded) {
              setShowStudentColourListUploadForm(true);
            }
            
        }
        setCurrentActiveCourse(response.data.current_active_course);

        setShowSpinner(false);
    });
  }, []);
  return (
    <>
      <div className="relative flex min-h-screen
                      flex-col justify-center overflow-hidden
                      py-6 sm:py-12
                      place-items-center
                      ">
        <div className="text-center mb-5 text-white text-2xl font-semibold">MarCHIR</div>
        <div className="container">
              <Navigation onClick={(navAction) => navigationAction(navAction)} />            
        </div>
        <div 
          className="
            container
          ">
            <div className='
                    ml-5 mr-5 relative
                    bg-white px-6 pt-10 pb-8
                    shadow-xl ring-1
                    ring-gray-900/5
                    md:mx-auto
                    md:max-w-xl
                    md:rounded-lg
                    md:px-10 border-2
                    border-sky-500
                    flex justify-center flex-col
                    '>
              <div className="mb-8">
                <div className="text-xl font-bold text-cyan-600 text-center">Create New Course</div>
              </div>

              <div className="mb-8">
                  <div className="text-lg font-medium">Current Active Course</div>
                  <div className="mt-5 font-semibold text-red-400">
                      {currentActiveCourse}
                  </div>
              </div>


              <div className={`mb-8 ${isDirectoryCreated ? 'hide-block' : ''}`}>
                <div className="text-lg font-medium">New Course Name</div>
                <div className="mt-5">
                  <div className="mb-3">
                    <label htmlFor="namePrefix">
                        <span className="pr-2">Name Prefix : </span>
                        <input
                            id="namePrefix"
                            type="text" 
                            name="namePrefix" 
                            className="border-solid rounded border-2 border-gray-200 py-1 px-2"
                            placeholder="HCI"
                            value={namePrefix}
                            onChange={(e) => setNamePrefix(e.target.value)}
                        />
                    </label>                  
                  </div>
                  <div className="mb-3">
                    <label htmlFor="namePrefix">
                        <span className="pr-2">Semester :</span>
                        <select 
                            name="semester" 
                            id="semester" 
                            className="border-solid rounded border-2 border-gray-200 py-1 px-2"
                            value={courseSemester}
                            onChange={(e) => setCourseSemester(e.target.value)}
                            >
                            <option value="S1">S1</option>
                            <option value="S2">S2</option>
                            <option value="SS">SS</option>                        
                        </select>
                    </label>
                  </div>
                  <div>
                    <label htmlFor="year">
                    <span className="pr-2">Year :</span>
                        <input
                            type="text" 
                            name="year" 
                            className="border-solid rounded border-2 border-gray-200 py-1 px-2"
                            placeholder="2023"
                            value={courseYear}
                            onChange={(e) => setCourseYear(e.target.value)}
                        />
                    </label>
                  </div>   
                  <div>
                    <button className="mt-5 bg-transparent hover:bg-blue-500 
                                      text-blue-700 font-semibold hover:text-white 
                                      py-1 px-3 border border-blue-500 
                                      hover:border-transparent rounded"
                                      onClick={createSubdirectory}
                                      >
                                        Create Subdirectory
                    </button>  
                  </div>               
                </div>
             </div>

             <div className={`mb-8 ${isDirectoryCreated ? '' : 'hide-block'}`}>
                <div className="text-lg font-medium">Course Name</div>
                <div className="mt-5">
                    The directory for the course {folderName} has already been created!
                </div>
             </div>
             
              <SupportFilesMarkUp />

              <ActiveCourseMarkup />

            </div>
          </div>
      </div>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)} >
        <div className="p-6">
          <h2 className="text-xl font-bold">Help (Admins)</h2>
          <div className="h-64 mt-5 overflow-y-scroll pr-5">
              <AdminHelpComponent />
          </div>
        </div>        
      </Modal>
      <Modal isVisible={showModal2} onClose={() => setShowModal2(false)} >
        <div className="p-6">
          <h2 className="text-xl font-bold">
            {alertMessage}
          </h2>          
        </div>        
      </Modal>
      <Spinner isVisible={showSpinner}/>      
    </>
  )
}
