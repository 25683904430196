import React from 'react'

export const AdminHelpComponent = () => {
  return (
    <>
              <div className="pb-5">
                  The admin page should allow you to manage the following:
              </div>

              <div className="pb-5">
                   1. Open and close submission at anytime
              </div>

              <div className="pb-5">
                   2. Schedule last date of submission which will automatically close submission
              </div>

              <div className="pb-5">
                   3. The course page will allow you to create a new course to which students will submit their assignments.
              </div>

              <div className="pb-5">
                   4. The CSV page will allow you to download the marker results which runs every 1 am in the morning.
              </div> 
    </>
  )
}
