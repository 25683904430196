import React from 'react'

export const ProtectedPage = () => {
  return (
    <div className="flex justify-center">
       <div 
        className="
          container
          w-10/12
        ">
        Hello Test <a href='/auth/logout'><button>Logout</button></a>
        </div>
    </div>
  )
}
