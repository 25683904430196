import React from 'react'
import './App.css'
import { AuthPage } from './pages/AuthPage'
import { ProtectedPage } from './pages/ProtectedPage'
import { UploadFilePage } from './pages/UploadFilePage'
import { AdminPage } from './pages/AdminPage'
import {NewCoursePage} from './pages/NewCoursePage'
import { AdminDownloadPage } from './pages/AdminDownloadPage'

const App = () => {

  switch(window.location.pathname) {
    case '/auth':
      return <div style={{backgroundImage: `url("../images/background-hci.jpg")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}><div className="custom-bg-overlay"><AuthPage/></div></div>
    case '/auth/protected_area':
      return <><ProtectedPage /></>
    case '/student-page':
      return <div style={{backgroundImage: `url("../images/background-hci.jpg")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}><div className="custom-bg-overlay"><UploadFilePage /></div></div>
    case '/admin-page':
      return <div style={{backgroundImage: `url("../images/background-hci.jpg")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}><div className="custom-bg-overlay"><AdminPage /></div></div>
    case '/admin-new-course':
      return <div style={{backgroundImage: `url("../images/background-hci.jpg")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}><div className="custom-bg-overlay"><NewCoursePage /></div></div>
    case '/admin-download-page':
      return <div style={{backgroundImage: `url("../images/background-hci.jpg")`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}><div className="custom-bg-overlay"><AdminDownloadPage /></div></div>
  }
  return (
    <>{window.location.pathname}</>
  )
}

export default App;
