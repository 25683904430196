import React, {useState, useEffect} from 'react';
import {MdHelpCenter} from 'react-icons/md';
import {RiLogoutBoxRFill} from 'react-icons/ri';
import {MdAssignmentAdd} from 'react-icons/md';
import {MdDashboard} from 'react-icons/md';
import {FaDownload} from 'react-icons/fa';
import axios from 'axios';


export const Navigation = ({onClick}) => {
    const [roles, setRoles] = useState([]);

    
    const getSessionVariables = () => {
        axios.get(
          "/api/session"
        ).then((response) => {
          setRoles(response.data["roles"]);          
        });
    }

    const NavigationSelector = () => {

        if(roles.includes("admin") || (roles.includes("super_admin"))) {
            return (
                <div className="border-0 md:mx-auto md:max-w-xl flex items-start mb-5">
                    <div className="text-white hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("help")}><MdHelpCenter className="text-2xl mr-2" />Help</div>
                    <div className="text-white hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("dashboard")}><MdDashboard  className="text-2xl mr-2" />Dashboard</div>
                    <div className="text-white hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("create-new-course")}><MdAssignmentAdd  className="text-2xl mr-2" />Course</div>
                    <div className="text-white hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("download-csv")}><FaDownload  className="text-xl mr-2" />CSV</div>
                    <div className="text-white hover:text-sky-400 flex items-start cursor-pointer" onClick={() => onClick("logout")}><RiLogoutBoxRFill  className="text-2xl mr-2" />Logout</div>
                </div>
            )
        }

        if(roles.includes("student")) {
            return (
                <div className="border-0 md:mx-auto md:max-w-xl flex items-start mb-5">
                    <div className="text-white hover:text-sky-400 flex items-start mr-6 cursor-pointer" onClick={() => onClick("help")}><MdHelpCenter className="text-2xl mr-2" />Help</div>
                    <div className="text-white hover:text-sky-400 flex items-start cursor-pointer" onClick={() => onClick("logout")}><RiLogoutBoxRFill  className="text-2xl mr-2" />Logout</div>
                </div>
            )
        }
    }


    useEffect(() => {
        getSessionVariables();    
    }, [])
    return (
        <NavigationSelector />
    )
}
