import React from 'react'

export const Modal = ({ isVisible, onClose, children }) => {
  if( !isVisible) return null;

  const handleClose = (e) => {
    if(e.target.id === 'wrapper') onClose();
  }

  return (
    <div className='fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-10' id="wrapper" onClick={handleClose}>
      <div className='w-[600px] flex flex-col'>
        <button className="text-white text-l place-self-end pt-1 pb-1 pl-3 pr-3 mb-2 bg-slate-400 rounded-full" onClick={() => onClose()}>X</button>
        <div className='bg-white p-2 rounded'>{children}</div>
      </div>      
    </div>
  )
}
