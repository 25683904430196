import React, {useState} from 'react';
import {FaUpload} from 'react-icons/fa';

export const FileFieldComponent = ({selectedFileName, onChangeHandler}) => {
  return (
    <div>
         <div className="flex items-stretch justify-start relative">
            <label className="flex items-stretch 
                              justify-start overflow-hidden 
                              relative cursor-pointer
                              ">
                <input
                    className="h-full w-full left-0 opacity-0 
                               outline-0 absolute top-0
                              "
                    type="file"
                    onChange={onChangeHandler}
                />
                <span className="file-cta">
                    <span className="flex items-center 
                                     justify-center mr-2
                                     h-4 w-4
                                     ">
                        <FaUpload />
                    </span>
                    <span className="flex items-stretch overflow-hidden 
                                     cursor-pointer 
                                     justify-start
                                     relative
                                     rounded-l-none                                     
                                    ">
                                        Choose a file…
                    </span>
                </span>
                <span className="file-name">{selectedFileName}</span>
            </label>
          </div>
    </div>
  )
}
