import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Modal } from '../components/Modal';
import { Navigation } from '../components/Navigation';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSave } from 'react-icons/bi';
import { Spinner } from '../components/Spinner';
import { AdminHelpComponent } from "../components/AdminHelpComponent";

export const AdminPage = () => {

  const [showModal, setShowModal] = useState(false);
  const [openSubmission, setOpenSubmission] = useState(false);
  const [submissionDate, setSubmissionDate] = useState(new Date());
  const [showSpinner, setShowSpinner] = useState(false);
  const [submissionTime, setSubmissionTime] = useState('');

  const [currentActiveCourse, setCurrentActiveCourse] = useState("");
   
  const navigationAction = (action) => {
    switch(action) {
      case "help": setShowModal(true); break;
      case "dashboard": window.location.href = "/admin-page"; break;
      case "create-new-course": window.location.href = "/admin-new-course"; break;
      case "download-csv": window.location.href = "/admin-download-page"; break;
      case "logout": window.location.href = "/auth/logout"; break;
    }
  }   

  const changeSubmissionStatus = () => {
    const currentSubmissionStatus = !openSubmission;
    
    setShowSpinner(true);

    axios.post(
      "/api/set-is-submission-open",
      {
        "open-submission" : currentSubmissionStatus ? "1" : "0"
      }
    ).then((response) => {
      console.log(response);
      setOpenSubmission(currentSubmissionStatus);
      setShowSpinner(false);
    })
  }

  const fetchSubmissionInfo = () => {
    setShowSpinner(true);
    axios.get(
      "/api/get-is-submission-open"
    ).then((response) => {
      const openSubmissionFlag = response.data.is_submission_open == "1" ? true : false;
      setOpenSubmission((openSubmission) => openSubmissionFlag);

      axios.get(
        "/api/get-last-submission-date"
      ).then((response) => {
        const currentLastSubmissionDate = new Date(response.data.last_submission_date);
        setSubmissionDate(currentLastSubmissionDate);

        axios.get(
          "/api/get-current-course"
        ).then((response) => {
            if(response.data.current_course) {
              console.log(response.data);
            }
            setCurrentActiveCourse(response.data.current_active_course);    

            axios.get(
              "/api/get-last-submission-time"
            ).then((response) => {
              console.log(response);
              setSubmissionTime(response.data.last_submission_time)
              setShowSpinner(false);
            });
            
        });

        setShowSpinner(false);
      })
    })
  }

  const handleSetSubmissionDate = (d) => {

      setShowSpinner(true);
      const lastSubmissionDateString = d.getFullYear() + "-" + (d.getMonth()+1) + "-" +  d.getDate()
      axios.post(
        "/api/set-last-submission-date",
        {
          "last-submission-date" : lastSubmissionDateString
        }
      ).then((response) => {
        console.log(response);
        setSubmissionDate(d)
        setShowSpinner(false);
      })      
  }

  const handleSetSubmissionTime = () => {
    setShowSpinner(true);
    axios.post(
      "/api/set-last-submission-time",
      {
        "last_submission_time" : submissionTime
      }
    ).then((response) => {
      setShowSpinner(false);
    })   
  }

  useEffect(() => {
    fetchSubmissionInfo();
  }, []);
  return (
    <>
      <div className="relative flex min-h-screen
                      flex-col justify-center overflow-hidden
                      py-6 
                      md:py-12
                      place-items-center
                      ">
        <div className="text-center mb-5 text-white text-2xl font-semibold">MarCHIR</div>
        <div className="container">
              <Navigation onClick={(navAction) => navigationAction(navAction)} />            
        </div>
        <div 
          className="
            container
          ">
            <div className='
                    ml-5 mr-5 relative
                    bg-white px-6 pt-10 pb-8
                    shadow-xl ring-1
                    ring-gray-900/5
                    md:mx-auto
                    md:max-w-xl
                    md:rounded-lg
                    md:px-10 border-2
                    border-sky-500
                    flex justify-center flex-col
                    '>
              <div className="mb-8">
                <div className="text-xl font-bold text-cyan-600 text-center">Main Admin Dashboard</div>
              </div>
              <div className="mb-8">
                  <div className="text-lg font-medium">Current Active Course</div>
                  <div className="mt-5 font-semibold text-red-400">
                      {currentActiveCourse}
                  </div>
              </div>
              <div className="mb-8">
                <div className="text-lg font-medium">Open or Close Submission</div>
                <div className="mt-5">
                  <label htmlFor="submissionIsOpen">
                    <input 
                        type="checkbox" 
                        className="default:ring-2 mr-2" 
                        checked={openSubmission}
                        onChange={changeSubmissionStatus}
                    />
                    Open submission
                  </label>                  
                </div>
              </div>
              <div className="mb-8">
                <div className="text-lg font-medium">Set Date Last Submission</div>
                <div className="mt-5 flex items-start">
                  <DatePicker 
                      selected={submissionDate} 
                      onChange={(date) => handleSetSubmissionDate(date)} 
                      dateFormat="yyyy-MM-dd hh:mm:ss"
                      inline
                  />
                  {/* <FaRegCalendarAlt className="text-4xl text-cyan-500 cursor-pointer hover:text-rose-600" onClick={() => clickCalendar()} /> */}
                </div>
              </div>     
              <div className="mb-8">
                <div className="text-lg font-medium">Set Time Last Submission</div>
                <div className="mt-5 flex items-center">
                    <div>
                        <input className="
                                   bg-gray-50 border
                                   border-gray-300 
                                   text-white 
                                   font-semibold text-lg
                                   rounded-lg 
                                  focus:ring-blue-500 
                                  focus:border-blue-500 block 
                                    w-20
                                    p-1.5
                                  dark:bg-gray-400 
                                  dark:border-gray-600 dark:placeholder-gray-300 
                                  dark:text-white dark:focus:ring-blue-500 
                                  dark:focus:border-blue-500"
                                  placeholder='01:00'
                                  value={submissionTime}
                                  onChange={(e) => setSubmissionTime(e.target.value)}
                                  /> 
                    </div>   
                    <div className="text-2xl ml-5 cursor-pointer hover:text-sky-600 flex items-center" onClick={handleSetSubmissionTime}>
                      <BiSave /> <span className="text-xl pl-1">update</span>
                    </div>               
                </div>
              </div>                         
            </div>
          </div>
      </div>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)} >
        <div className="p-6">
          <h2 className="text-xl font-bold">Help (Admins)</h2>
          <div className="h-64 mt-5 overflow-y-scroll pr-5">
              <AdminHelpComponent />
          </div>
        </div>        
      </Modal>  
      <Spinner isVisible={showSpinner}/>
    </>
  )
}
