import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Modal } from '../components/Modal';
import { Navigation } from '../components/Navigation';
import { Spinner } from '../components/Spinner';
import { FileFieldComponent } from '../components/FileFieldComponent';

export const UploadFilePage = () => {
 
  const [selectedFile, setSelectedFile] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [okToRender, setOkToRender] = useState(true);
  const [selectedObject, setSelectedObject] = useState(null);
  const [isSubmissionOpen, setIsSubmissionOpen] = useState(1);
  const [showSpinner, setShowSpinner] = useState(true);
  
  const submitHandler = (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("uploadedFile", selectedFile);

    axios.post(
      "/api/upload-file",
      formData,
      {
        headers: {
          "Content-Type" : "multipart/form-data",
          "Accept" : "application/json"
        }
      }
    ).then((response) => {
      console.log(response);
      updateUploadedFileList();      
    })
  }
  
  const selectItem = (id) => {

    const selectedUploadedFile = uploadedFiles.filter(item => item.id == id);

    try {
      setSelectedObject((currentObj) => currentObj = JSON.parse(selectedUploadedFile[0].result));
      setOkToRender(true);
    } catch (e) {
      setSelectedObject({"error":  selectedUploadedFile[0].result });
      setOkToRender(false);
    }

    setShowModal(true);
  }

  const fileChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  }


  const updateUploadedFileList = () => {
    axios.get(
      "/api/get-uploaded-files"
    ).then((response) => {
      setUploadedFiles(response.data)
    })
  }

  const fileProcessResult = () => {
    
    if(!okToRender) return (
      <div className="mb-3 border-b-2 pb-2">
        <h3 className="text-xl font-bold">Errors</h3>
      </div>
    )

    
    const renderFileFormatValidator = () => {      
      if (selectedObject) {
        if (selectedObject.hasOwnProperty('fileFormatValidator')) {
          return selectedObject.fileFormatValidator.map((entry, index) => {
            if(entry[1])
              return <li key={index} className="text-green-600 font-bold">{entry[2]}</li>
            else
              return <li key={index} className="text-red-600 font-bold">{entry[2]}</li>
          })
        } else {
          return <>
            <li className="text-red-600 font-bold">Something is wrong with your submission. Cannot locate a valid index.html file.</li>
            <li className="text-red-600 font-bold">Please follow instructions on how to properly zip files. Check the video on the help section of this page.</li>
          </>
        }
        
      } else {
        return <>
          <li className="text-red-600 font-bold">Something is wrong with your submission. Cannot locate a valid index.html file.</li>
          <li className="text-red-600 font-bold">Please follow instructions on how to properly zip files. Check the video on the help section of this page.</li>
        </>
      }
    }

    const renderformValidator = () => {
      if (selectedObject) {
        
        if (selectedObject.hasOwnProperty('formValidator')) {
          return selectedObject.formValidator.map((entry, index) => {
            if(entry[1])
              return <li key={index} className="text-green-600 font-bold">{entry[2]}</li>
            else
              return <li key={index} className="text-red-600 font-bold">{entry[2]}</li>
          })
        } else {
          return <>
            <li className="text-red-600 font-bold">Something is wrong with your submission. Cannot locate a valid index.html file.</li>
            <li className="text-red-600 font-bold">Please follow instructions on how to properly zip files. Check the video on the help section of this page.</li>
          </>
        }        
      } else {
        return <>
          <li className="text-red-600 font-bold">Something is wrong with your submission. Cannot locate a valid index.html file.</li>
          <li className="text-red-600 font-bold">Please follow instructions on how to properly zip files. Check the video on the help section of this page.</li>
        </>
      }
    }

    const renderSelectorsValidator = () => {
      if (selectedObject) {
        if(selectedObject.hasOwnProperty('selectorsValidator')) {
          return selectedObject.selectorsValidator.map((entry, index) => {
            if(entry[1])
              return <li key={index} className="text-green-600 font-bold">{entry[2]}</li>
            else
              return <li key={index} className="text-red-600 font-bold">{entry[2]}</li>
          })
        } else {
          return <>
            <li className="text-red-600 font-bold">Something is wrong with your submission. Cannot locate a valid index.html file.</li>
            <li className="text-red-600 font-bold">Please follow instructions on how to properly zip files. Check the video on the help section of this page.</li>
          </>
        }
      } else {
        return <>
          <li className="text-red-600 font-bold">Something is wrong with your submission. Cannot locate a valid index.html file.</li>
          <li className="text-red-600 font-bold">Please follow instructions on how to properly zip files. Check the video on the help section of this page.</li>
        </>
      }
    }

    
    
    return (
        <>
            <div className="mb-3 border-b-2 pb-2">
              <h3 className="text-xl font-bold">Files</h3>
              <ul className="list-disc list-inside">
                {renderFileFormatValidator()}
              </ul>
            </div>

            <div className="mb-3 border-b-2 pb-2">
              <h3 className="text-xl font-bold">Form</h3>
              <ul className="list-disc list-inside">
                {renderformValidator()} 
              </ul>
            </div>

            <div className="mb-3 border-b-2 pb-2">
              <h3 className="text-xl font-bold">Selectors</h3>
              <ul className="list-disc list-inside">
                {renderSelectorsValidator()}
              </ul>
            </div>
        </>      
    )
  }

  const navigationAction = (action) => {
    switch(action) {
      case "help": setShowModal2(true); break;
      case "logout": window.location.href = "/auth/logout"; break;
    }
  }

  const SubmitAssignmentForm = () => {

    if (isSubmissionOpen) {
      return  <form method="post" onSubmit={submitHandler}>
                <div className="flex items-center flex-col">
                  <FileFieldComponent selectedFileName={selectedFile.name}  onChangeHandler={(e) => fileChangeHandler(e) }/>                  
                  <button type="submit" className="mt-5 mb-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded">Submit Zip File</button>
                </div>
                <div>
                  Due to a huge number of submissions, the validator might take a while to process. Please wait until your last submitted file has been processed. Refresh the page to view the latest update.
                </div>
              </form>
    } else {
      return <div className="text-lg font-semibold">
        Sorry! Submission is closed.
      </div>
    }
  }


  const TableRowContent = (item) => {

    if(item.status == 'validated' || item.status == 'complete') {
      return  <tr key={item.id} onClick={() => selectItem(item.id)} className="item-done">
                <td>{item.id}</td>
                <td>{item.upi}</td>
                <td>{item.filename}</td>
                <td>done</td>
              </tr>
    } else {
      return  <tr key={item.id} className="item-processing">
                <td>{item.id}</td>
                <td>{item.upi}</td>
                <td>{item.filename}</td>
                <td>{item.status}</td>
              </tr>
    }
  }

  useEffect(() => {
    axios.get(
      "/api/current-submission-date"
    ).then((response) => {      
      setIsSubmissionOpen(response.data.is_submission_open);
      setShowSpinner(false)
   })
  }, []);
  useEffect(() => {
    updateUploadedFileList();
  }, [])
  return (
    <>
      <div className="relative flex min-h-screen 
                      flex-col justify-center overflow-hidden 
                      py-6 sm:py-12
                      place-items-center
                      ">
        <div className="text-center mb-5 text-white text-2xl font-semibold">MarCHIR</div>
          <div className="container">
              <Navigation onClick={(navAction) => navigationAction(navAction)} />            
          </div>
          <div className="container">
            <div className='
                    ml-5 mr-5 relative
                    bg-white px-6 pt-10 pb-8
                    shadow-xl ring-1
                    ring-gray-900/5
                    md:mx-auto
                    md:max-w-xl
                    md:rounded-lg
                    md:px-10 border-2
                    border-sky-500
                    flex justify-center flex-col     
                    place-items-center           
                    '>
              <div className="mb-8">
                <div className="text-xl font-bold text-cyan-600 text-center">Student Dashboard</div>
              </div>                         
                <SubmitAssignmentForm />
            </div>
          </div>

          <div 
          className="
            container
          ">
            <div className='
                    mt-10 ml-5 mr-5 relative 
                    bg-white px-6 pt-10 pb-8 
                    shadow-xl ring-1 
                    ring-gray-900/5 
                    md:mx-auto
                    md:max-w-xl
                    md:rounded-lg
                    md:px-10 border-2
                    border-sky-500 
                    flex justify-center flex-col       
                    place-items-center           
                    '>
                      <table className="w-full table-auto custom-table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>UPI</th>
                            <th>FileName</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            uploadedFiles.map((item) => TableRowContent(item))
                          }                      
                        </tbody>
                      </table>
                      
            </div>
          </div>        
      </div>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)} >
        <div className="p-6">
          <h2 className="text-xl font-bold">Validation Result</h2>
          <div className="h-64 mt-5 overflow-y-scroll">
              { fileProcessResult() }
          </div>
        </div>        
      </Modal>
      <Modal isVisible={showModal2} onClose={() => setShowModal2(false)} >
        <div className="p-6">
          <h2 className="text-xl font-bold text-cyan-600">Help (Students)</h2>
          <div className="h-64 mt-5 overflow-y-scroll pr-5">
              <div className="pb-5">
                  Using zip, compress your files (index.html, index.css, image1.jpg...) into a single zip file. 
                  Double check when you extract the files it should not be contained in a folder otherwise the validator will return an error. 
                  (Check out the following video to see how to properly zip your files: <a href="https://drive.google.com/file/d/1D92WzfJF2hUkb-cXgSAv6s8lBrRslUP9/view" target="_blank" className="text-sky-600 hover:text-sky-400 hover:underline ">Using Winzip</a>)
              </div>

              <div className="pb-5">
                   Submit the zip file and wait until the process is complete.
              </div>

              <div className="pb-5">
                   The row of the entry you just submitted will have a status done. 
                   Click the row to view the result of the validation.
              </div>

              <div className="pb-5">
                   One of the common mistake we found on the submitted assignments is the use of incorrect double quotes for form id name. 
                   For example: id=“ct-form”. This ussually happens when you copy and paste from a word document. In the example the double quotes use was the special characters “ and ”. 
                   You should use the standard double quote found in your keyboard.
              </div>
          </div>
        </div>        
      </Modal>
      <Spinner isVisible={showSpinner} />
    </>
  )
}


