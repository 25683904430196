import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Modal } from '../components/Modal';
import { Navigation } from '../components/Navigation';
import { Spinner } from '../components/Spinner';
import {HiOutlineDocumentDownload} from 'react-icons/hi';
import { AdminHelpComponent } from "../components/AdminHelpComponent";

export const AdminDownloadPage = () => {

    const [showModal, setShowModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [csvFiles, setCsvFiles] = useState([]);

    const navigationAction = (action) => {
      switch(action) {
        case "help": setShowModal(true); break;
        case "dashboard": window.location.href = "/admin-page"; break;
        case "create-new-course": window.location.href = "/admin-new-course"; break;
        case "download-csv": window.location.href = "/admin-download-page"; break;
        case "logout": window.location.href = "/auth/logout"; break;
      }
    }

  const CsvFileList = () => {

    let baseURL = "http://" + window.location.host + "/downloads/";

    if (window.location.origin.startsWith('https://')) {
      baseURL = "https://" + window.location.host + "/downloads/";
    }

    return csvFiles.map((item) => (
      <div className="hover:text-sky-400 mr-6 mb-3" key={item.id}>
          <a href={baseURL+item.name+".csv"} className='flex items-start'><HiOutlineDocumentDownload className="text-2xl mr-2" /> {item.name}</a>
      </div>
    ));
  }

  useEffect(() => {
    setShowSpinner(true)
    axios.get(
      "/api/get-download-ready-courses"
    ).then((response) => {
      setCsvFiles(response.data);
      setShowSpinner(false)
    })
  }, []);
  return (
    <>
      <div className="relative flex min-h-screen
                      flex-col justify-center overflow-hidden
                      py-6 
                      md:py-12
                      place-items-center
                      ">
        <div className="text-center mb-5 text-white text-2xl font-semibold">MarCHIR</div>
        <div className="container">
              <Navigation onClick={(navAction) => navigationAction(navAction)} />            
        </div>
        <div 
          className="
            container
          ">
            <div className='
                    ml-5 mr-5 relative
                    bg-white px-6 pt-10 pb-8
                    shadow-xl ring-1
                    ring-gray-900/5
                    md:mx-auto
                    md:max-w-xl
                    md:rounded-lg
                    md:px-10 border-2
                    border-sky-500
                    flex justify-center flex-col
                    '>
              <div className="mb-8">
                <div className="text-xl font-bold text-cyan-600 text-center">Download CSV</div>
              </div>                        
              <div className="mb-8">
                <div className="text-lg font-medium mb-5">Courses Already Processed</div>
                <div className="mb-5">
                  <CsvFileList />
                  

                  {/* <div className="hover:text-sky-400 mr-6 mb-3">
                      <a href="http://localhost:8080/downloads/HCIS22023.csv" className='flex items-start'><HiOutlineDocumentDownload className="text-2xl mr-2" /> HCIS22023</a>
                  </div>
                  <div className="hover:text-sky-400 mr-6 mb-3">
                      <a href="http://localhost:8080/downloads/HCIS22023.csv" className='flex items-start'><HiOutlineDocumentDownload className="text-2xl mr-2" /> HCIS22023</a>
                  </div> */}
                </div>
              </div>              
            </div>
          </div>
      </div>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)} >
        <div className="p-6">
          <h2 className="text-xl font-bold">Help (Admins)</h2>
          <div className="h-64 mt-5 overflow-y-scroll pr-5">
              <AdminHelpComponent />
          </div>
        </div>        
      </Modal>  
      <Spinner isVisible={showSpinner}/>
    </>
  )
}
