import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { Spinner } from '../components/Spinner';

export const AuthPage = () => {
  
  const [remainingDays, setRemainingDays] = useState('0');
  const [isSubmissionOpen, setIsSubmissionOpen] = useState(1);
  const [submissionDateDeadline, setSubmissionDateDeadline] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);
  const [submissionTime, setSubmissionTime] = useState('');

  const ContentOutput = () => {

    if(isSubmissionOpen) {
      return <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 sm:py-12">
                <div className="text-center mb-5 text-white text-2xl font-semibold">Web app submission</div>
                <div className='ml-5 mr-5 relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10 border-2 border-sky-500 flex justify-center flex-col'>
                  <div className="mb-5">Submit and validate your HCI assignment</div>
                  <a className="pl-10 pr-10 pb-2 pt-2 bg-sky-500 text-white rounded-md text-center" href="/auth/login">
                    Login with UoA UPI
                  </a>
                </div>
                <div className="text-center mb-1 text-white text-2xl font-semibold mt-5 flex justify-center">
                  <div className="remaining-days">{remainingDays}</div>
                </div>
                <div className="text-center mb-5 text-white text-lg font-thin"><div className="date_sumission_deadline">Days till submission closes</div></div>
                <div className="text-center mb-5 text-white text-2xl font-semibold"><div className="date_sumission_deadline">Will close on : {submissionDateDeadline} {submissionTime} </div></div>
            </div>
    } else {
      return <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 sm:py-12">
                <div className="text-center mb-5 text-white text-2xl font-semibold">Web app submission</div>
                <div className='ml-5 mr-5 relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10 border-2 border-sky-500 flex justify-center flex-col'>
                  <div className="mb-5">Submit and validate your HCI assignment</div>
                  <a className="pl-10 pr-10 pb-2 pt-2 bg-sky-500 text-white rounded-md text-center" href="/auth/login">
                    Login with UoA UPI
                  </a>
                </div>
                <div className="text-center mt-10 mb-5 text-white text-2xl font-semibold"><div className="date_sumission_deadline">Submission is now closed!</div></div>
                <div className="text-center mb-2 text-white text-lg font-thin"><div className="date_sumission_deadline">You will still be able to login and view previous submission,</div></div>
                <div className="text-center mb-5 text-white text-lg font-thin"><div className="date_sumission_deadline">but you cannot submit anymore.</div></div>
            </div>
      // return <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 sm:py-12">
      //           <div className="text-center mb-5 text-white text-2xl font-semibold">Web app submission</div>
      //           <div className='ml-5 mr-5 relative bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10 border-2 border-sky-500 flex justify-center flex-col'>
      //             <div className="mb-5">Submit and validate your HCI assignment</div>
      //             <a className="pl-10 pr-10 pb-2 pt-2 bg-sky-500 text-white rounded-md text-center" href="/auth/login">
      //               Login with UoA UPI
      //             </a>
      //           </div>
      //           <div className="text-center mt-10 mb-5 text-white text-2xl font-semibold"><div className="date_sumission_deadline">Submission is temporarily closed!</div></div>
      //           <div className="text-center mb-2 text-white text-lg font-thin"><div className="date_sumission_deadline">Due to an influx of student submissions, there is a huge amount of jobs pending.</div></div>                
      //           <div className="text-center mb-2 text-white text-lg font-thin"><div className="date_sumission_deadline">You can still login to check your last submitted file.</div></div>
      //           <div className="text-center mb-2 text-white text-lg font-thin"><div className="date_sumission_deadline">Submissions will resume soon.</div></div>
      //           <div className="text-center mb-5 text-white text-lg font-thin"><div className="date_sumission_deadline">Sorry for the inconvenience.</div></div>
      //       </div>      
    }
  }

  useEffect(() => {
    axios.get(
      "/api/current-submission-date"
    ).then((response) => {      
      setRemainingDays(response.data.remaining_days);
      setIsSubmissionOpen(response.data.is_submission_open);
      setSubmissionDateDeadline(response.data.submission_date_deadline);

      axios.get(
        "/api/get-last-submission-time"
      ).then((response) => {        
        setSubmissionTime(response.data.last_submission_time)
        setShowSpinner(false);
      });
    })
  
  }, []);



  return (
    <>
      <ContentOutput />
      <Spinner isVisible={showSpinner} />
    </>
  )
}
